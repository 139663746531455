var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentWrap"},[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"理事会","name":"first"}},[_c('div',{staticClass:"council-position"},[_vm._v("理事长")]),_c('div',{staticClass:"council-name"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.councilContent.council
              .map(_vm.formatName)
              .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
          )}})]),_c('div',{staticClass:"council-position"},[_vm._v("副理事长( 以姓氏笔画为序 )")]),_c('div',{staticClass:"council-name"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.councilContent.viceCouncil
              .map(_vm.formatName)
              .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
          )}})]),_c('div',{staticClass:"council-position"},[_vm._v("秘书长")]),_c('div',{staticClass:"council-name"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.councilContent.secretaryGeneral.join(
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            )
          )}})]),_c('div',{staticClass:"council-position"},[_vm._v("理事 ( 以姓氏笔画为序 )")]),_c('div',{staticClass:"council-name"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.councilContent.trustee
              .map(_vm.formatName)
              .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
          )}})])]),_c('el-tab-pane',{attrs:{"label":"监事会","name":"second"}},[_c('div',{staticClass:"council-position"},[_vm._v("监事长")]),_c('div',{staticClass:"council-name"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.supervisors.chiefSupervisor
              .map(_vm.formatName)
              .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
          )}})]),_c('div',{staticClass:"council-position"},[_vm._v("监事 ( 以姓氏笔画为序 )")]),_c('div',{staticClass:"council-name"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.supervisors.supervisor
              .map(_vm.formatName)
              .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
          )}})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }