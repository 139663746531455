<template>
  <div class="contentWrap">
    <el-tabs v-model="activeName">
      <el-tab-pane label="理事会" name="first">
        <div class="council-position">理事长</div>
        <div class="council-name">
          <span
            v-html="
              councilContent.council
                .map(formatName)
                .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
            "
          ></span>
        </div>

        <div class="council-position">副理事长( 以姓氏笔画为序 )</div>
        <div class="council-name">
          <span
            v-html="
              councilContent.viceCouncil
                .map(formatName)
                .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
            "
          ></span>
        </div>

        <div class="council-position">秘书长</div>
        <div class="council-name">
          <span
            v-html="
              councilContent.secretaryGeneral.join(
                '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
              )
            "
          ></span>
        </div>

        <div class="council-position">理事 ( 以姓氏笔画为序 )</div>
        <div class="council-name">
          <span
            v-html="
              councilContent.trustee
                .map(formatName)
                .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
            "
          ></span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="监事会" name="second">
        <div class="council-position">监事长</div>
        <div class="council-name">
          <span
            v-html="
              supervisors.chiefSupervisor
                .map(formatName)
                .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
            "
          ></span>
        </div>
        <div class="council-position">监事 ( 以姓氏笔画为序 )</div>
        <div class="council-name">
          <span
            v-html="
              supervisors.supervisor
                .map(formatName)
                .join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
            "
          ></span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import cnchar from "cnchar";
export default {
  name: "council",
  data() {
    return {
      councilContent: {
        council: [],
        viceCouncil: [],
        secretaryGeneral: [],
        trustee: [],
      },
      activeName: "first",
      supervisors: {
        chiefSupervisor: [],
        supervisor: [],
      },
      allCouncilName: "councilContent.councilName",
    };
  },
  created() {
    this.getCouncil();
    this.getSupervisors();
  },

  methods: {
    // 理事会接口
    getCouncil() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafCouncil/tableList.ajax",
        data: {
          pageSize: "200",
          pageNo: "1",
          condition: "type:S_IN0|councilOrder:@第三届||",
          _entityName: "org.aweto.laf.entity.LafCouncil",
        },
      })
        .then((res) => {
          console.log(res.rows.map((item) => item.councilName));
          const council = [];
          const viceCouncil = [];
          const secretaryGeneral = [];
          const trustee = [];
          res.rows.forEach((item) => {
            switch (item.councilPosition) {
              case "理事长":
                council.push(item.councilName);
                break;
              case "副理事长":
                viceCouncil.push(item.councilName);
                break;
              case "秘书长":
                secretaryGeneral.push(item.councilName);
                break;
              case "理事":
                trustee.push(item.councilName);
            }
          });
          this.councilContent.council = this.sortNameByStroke(council);
          this.councilContent.viceCouncil = this.sortNameByStroke(viceCouncil);
          this.councilContent.secretaryGeneral =
            this.sortNameByStroke(secretaryGeneral);
          this.councilContent.trustee = this.sortNameByStroke(trustee);
        })

        .catch(function (error) {
          console.log(error);
        });
    },

    sortNameByStroke(names) {
      return names.sort((a, b) => {
        if (a && b) return a.charAt(0).stroke() < b.charAt(0).stroke() ? -1 : 0;
        else return 0;
      });
    },
    //  监事会接口
    getSupervisors() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafCouncil/tableList.ajax",
        data: {
          pageSize: "200",
          pageNo: "1",
          condition: "type:S_IN1|councilOrder:@第三届||",
          _entityName: "org.aweto.laf.entity.LafCouncil",
        },
      })
        .then((res) => {
          this.supervisors = res.rows;
          const chiefSupervisor = [];
          const supervisor = [];
          res.rows.forEach((item) => {
            switch (item.councilPosition) {
              case "监事长":
                chiefSupervisor.push(item.councilName);
                break;
              case "监事":
                supervisor.push(item.councilName);
                break;
            }
          });
          this.supervisors.chiefSupervisor =
            this.sortNameByStroke(chiefSupervisor);
          this.supervisors.supervisor = this.sortNameByStroke(supervisor);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formatName(ele) {
      ele = ele.replace(/[ ]+/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
      return ele;
    },
  },
};
</script>

<style lang='scss' scoped>
.img-pic {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.contentWrap {
  ::v-deep .el-tabs__nav {
    margin-left: 20px;
    // margin-bottom: 25px;
  }

  ::v-deep.el-tabs__item {
    font-size: 18px;
  }
  ::v-deep.el-tabs__item:hover {
    color: $red;
  }

  ::v-deep .el-tabs__item.is-active {
    color: $red;
  }
  ::v-deep .contentWrap[data-v-347c7af5] .el-tabs__item.is-active {
    color: $red;
  }
  .contentItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    height: 220px;
    cursor: pointer;
    &:hover {
      background-color: #f8f8f8;
    }

    .pic {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #badde1;
    }
    .item {
      float: right;
      margin-left: 25px;
    }
    .item :nth-child(1) {
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 30px;
      font-weight: 700;
      word-wrap: break-word;
      width: 90px;
    }
    .item :nth-child(2) {
      font-size: 16px;
      margin-bottom: 30px;
      word-wrap: break-word;
      width: 90px;
    }
  }
}
.council-position {
  text-indent: 40px;
  font-size: 18px;
  line-height: 30px;
  // font-weight: bold;
  font-weight: bold;
  color: #cd242a;
}
.council-name {
  text-indent: 40px;
  font-size: 16px;
  color: #333;
  margin: 20px 0 30px 0;
}
.councilName {
  margin-left: 95px;
  font-size: 14px;
  line-height: 30px;
  color: #444;
}

.linkTwo {
  border-top: solid #eee 1px;
}
.link {
  // width: 880px;
  margin-top: 20px;
  border-top: solid #e5e5e5 1px;
}
::v-deep .el-tabs__content {
  border-left: 0;
}
::v-deep {
  .el-tabs__active-bar {
    background-color: $red;
  }
}
.el-main {
  padding: 35px;
}
</style>